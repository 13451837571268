<template>
  <div>
    <!-- <p>{{ accessRules }}</p> -->
    <!-- DATA TABLE -->
    <div>
      <v-data-table
        :headers="headers"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        :items="accessRules"
        item-key="_id"
        :items-per-page="10"
        show-expand
        class="elevation-1"
      >
        <template v-slot:item.action="{ item }">
          <v-icon color="red" @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pa-6">
            <p>
              <strong>Description:</strong>
              {{ item.description ?  item.description : 'Pas de description'}}
            </p>
            <p>
              <strong>Portes:</strong> {{ getDoorName(item.openings) }}
            </p>
            <UserTableBadge
              v-if="item.mode === 'BADGE'"
              :badgesUUID="item.badgesList ? item.badgesList.map((e) => e.uuid) : []"
            />
          </td>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import UserTableBadge from './UserTableBadge.vue';

export default {
  name: 'AccessRuleTable',
  components: {
    UserTableBadge,
  },
  data() {
    return {
      headers: [
        {
          text: 'Nom',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        { text: 'Mode', value: 'mode' },
        { text: 'Period', value: 'cycle' },
        { text: 'Badges', value: 'badgeQty' },
        { text: 'Début', value: 'initTime' },
        { text: 'Fin', value: 'endTime' },
        { text: 'Actions', value: 'action', sortable: false },
      ],
      expanded: [],
      singleExpand: true,
      accessRules: [],
      isAccessRuleReady: false,
      doors: [],
    };
  },
  created() {
    this.getDoors();
    this.getAccessRule();
  },
  methods: {
    async getAccessRule() {
      const accessRules = await this.$apollo.query({
        query: gql`
          query {
          accessRules {
            _id
            badgesList {
              uuid
              macAddr
            }
            description
            duration
            enabled
            initDate
            initTime
            mode
            name
            openings
            weekDays
          }
        }`,
        variables: {
          query: {
          },
        },
        fetchPolicy: 'network-only',
      });
      let tempAccessRule = accessRules.data.accessRules;
      tempAccessRule = tempAccessRule.map((elm) => {
        // -- Add badge qty on mode badge
        let badgeQty = '';
        if (elm.mode === 'BADGE') {
          badgeQty = elm.badgesList === null ? 'all' : String(elm.badgesList.length);
        }
        // -- Display end time and time duration in HH:MM format

        const d = Number(elm.duration);
        const separator = ['h', 'H', ':'].filter((e) => elm.initTime.includes(e));
        const initTimeFixed = elm.initTime.split(separator[0]);
        const hInSecond = Number(initTimeFixed[0]) * 3600;
        const mInSecond = Number(initTimeFixed[1]) * 60;
        const totalInSecond = hInSecond + mInSecond + (d || 0);
        const endTime = new Date(totalInSecond * 1000).toISOString().substr(11, 5);
        // const endTime = `${h + timeH}h${m + timeM}`;
        const timeDuration = new Date(hInSecond + mInSecond * 1000).toISOString().substr(11, 5);

        // -- Get date on unique event or days on repeat
        let cycle = '';
        if (elm.initDate) {
          const [eventDate] = elm.initDate.split('T');
          cycle = eventDate.split('-').reverse().join('/');
        } else {
          cycle = elm.weekDays.join(',');
        }
        return {
          ...elm,
          badgeQty,
          endTime,
          timeDuration,
          cycle,
        };
      });
      this.accessRules = tempAccessRule;
      this.isAccessRuleReady = true;
      return accessRules.data.accessRules;
    },
    async getDoors() {
      // console.log('SEND MAIL TO: ', this.companyId, this.title);
      const doors = await this.$apollo.query({
        query: gql`
          query openings ($query: OpeningQueryInput) {
          openings (query: $query) {
            openingId
            name
          }
        }`,
        fetchPolicy: 'network-only',
      });
      this.doors = doors.data.openings;
      return doors.data.openings;
    },
    getDoorName(openings) {
      const doorsName = [];
      openings.forEach((doorId) => {
        const door = this.doors.find((elm) => elm.openingId === doorId);
        doorsName.push(door ? door.name : 'DEMO');
      });
      return doorsName.join(', ');
    },
    async deleteItem(item) {
      const ruleId = item._id;
      await this.$apollo.mutate({
        mutation: gql`
          mutation deleteOneAccessRule ($query: AccessRuleQueryInput!) {
            deleteOneAccessRule (query: $query) {
              _id
            }
          }`,
        variables: {
          query: {
            _id: ruleId,
          },
        },
      });
      this.getAccessRule();
    },
  },
};
</script>
