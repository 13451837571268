<template>
  <div>
    <div class="organism-head">
      <h1>Plages d'accès</h1>
      <AccessFormBtn @refresh="refresh = !refresh"/>
    </div>
    <AccessRuleTable :key="refresh"/>
  </div>
</template>

<script>
import AccessFormBtn from './AccessFormBtn.vue';
import AccessRuleTable from './AccessRuleTable.vue';

export default {
  components: {
    AccessFormBtn,
    AccessRuleTable,
  },
  data: () => ({
    refresh: false,
  }),
};
</script>

<style scoped>
.organism-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
</style>
