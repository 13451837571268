<template>
  <div class="about pa-12">
    <AccessOrganism/>
  </div>
</template>

<script>
import AccessOrganism from '../components/AccessOrganism.vue';

export default {
  components: {
    AccessOrganism,
  },
};
</script>
