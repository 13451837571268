<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="1200"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          dark
          color="indigo"
          style="width:250px;"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon left>
            mdi-door-closed-lock
          </v-icon>
          <span>Ajouter une règle</span>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2">
          Règle d'accès
        </v-card-title>

        <v-card-text>
          <AccessForm
            @accessFormSent="handleAccessForm"
          >
          </AccessForm>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AccessForm from './AccessForm.vue';

export default {
  components: {
    AccessForm,
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    handleAccessForm() {
      this.dialog = false;
      this.$emit('refresh');
    },
  },
};
</script>

<style scoped>
.super-class {
  background: #4b79b6;
}
</style>
