<template>
  <div class="main-css">
    <v-form v-model="valid">
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="name"
              :rules="nameRules"
              :counter="50"
              label="Nom de la règle"
              required
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="description"
              :rules="descriptionRules"
              label="Description"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="mode"
              :rules="modeRules"
              :items="modeItems"
              label="Mode"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
           <v-col
            cols="12"
            md="4"
            >
            <v-select
              v-model="type"
              :rules="typeRules"
              :items="typeItems"
              label="Type"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="4"
            v-if="type==='repeat'"
          >
            <v-select
              v-model="weekDays"
              :items="weekDaysItems"
              :rules="weekDaysRules"
              attach
              label="Jours"
              multiple
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="4"
            v-if="type==='unique'"
          >
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Picker in menu"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="menu = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col
            cols="12"
            md="2"
          >
             <v-text-field
              v-model="initTime"
              :rules="initTimeRules"
              :counter="5"
              label="Heure de début (8h30)"
              required
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
             <v-text-field
              v-model="endTime"
              :rules="endTimeRules"
              :counter="5"
              label="Heure de fin (19h30)"
              required
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="openings"
              :items="openingByName"
              :rules="openingsRules"
              attach
              label="Portes d'accès"
              multiple
            ></v-select>
          </v-col>
        </v-row>

        <v-row v-if="mode === 'BADGE'">
          <v-col
            cols="12"
            md="12"
          >
            <h2>Liste des badges
              <span class="caption">(Si aucun badges n'est selectionné
                tous les badges seront pris en compte)
              </span>
            </h2>
            <UserTableWithSelect @selected="updateBadgeList"/>
          </v-col>
        </v-row>
      </v-container>

      <div class="btn-validation">
        <v-btn
          :disabled="!valid"
          color="success"
          class="mr-4"
          @click="validate"
        >
          Validate
        </v-btn>

        <v-btn
          color="error"
          class="mr-4"
          @click="reset"
        >
          Reset Form
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import UserTableWithSelect from './UserTableWithSelect.vue';

export default {
  name: 'AccessForm',
  components: {
    UserTableWithSelect,
  },
  data() {
    return {
      doors: [],
      valid: false,
      name: '',
      nameRules: [
        (v) => !!v || 'Le nom est obligatoire',
        (v) => v.length <= 50 || 'Le nom doit faire moins de 50 caractères',
      ],
      description: '',
      descriptionRules: [
        (v) => v.length <= 150 || 'La description doit faire moins de 150 caractère',
      ],
      mode: 'BADGE',
      modeItems: ['BADGE', 'OPEN', 'CLOSED'],
      modeRules: [
        (v) => !!v || 'Le mode est obligatoire',
        (v) => ['BADGE', 'OPEN', 'CLOSED'].includes(v) || 'le mode peut être badge, open ou closed',
      ],
      type: 'repeat',
      typeItems: ['repeat', 'unique'],
      typeRules: [
        (v) => !!v || 'Le type est obligatoire',
        (v) => ['repeat', 'unique'].includes(v) || 'le type peut être repeat ou unique',
      ],
      weekDays: [],
      weekDaysItems: ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'],
      weekDaysRules: [
        (v) => v.length > 0 || 'Choisir au moins 1 jour de la semaine',
      ],
      initDate: '',
      initDateRules: [
        (v) => v.length > 0 || 'Choisir 1 jour',
      ],
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      initTime: '',
      initTimeRules: [
        (v) => !!v || 'L\'heure de début est obligatoire',
        (v) => v.match('\\d{1,2}[h]\\d{1,2}') || 'L\'heure est au format 14h27',
      ],
      endTime: '',
      endTimeRules: [
        (v) => !!v || 'L\'heure de fin est obligatoire',
        (v) => v.match('\\d{1,2}[h]\\d{1,2}') || 'L\'heure est au format 14h27',
      ],
      openings: [],
      openingsItems: ['0', '1', '2', '3'],
      openingsRules: [
        (v) => v.length > 0 || 'Choisir au moins 1 porte d\'accès',
      ],
      badgesList: [],
    };
  },
  created() {
    this.getDoors();
  },
  computed: {
    openingByName() {
      return this.openingsItems.map((e) => `${e.name} - ${e.openingId}`);
    },
  },
  methods: {
    validate() {
      this.saveAccessRule();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    updateBadgeList(badges) {
      this.badgesList = badges;
    },
    getDuration() {
      if (this.initTime.length >= 3 && this.endTime.length >= 3) {
        const separatorInit = ['h', 'H', ':'].filter((e) => this.initTime.includes(e));
        const separatorEnd = ['h', 'H', ':'].filter((e) => this.endTime.includes(e));
        const initT = this.initTime.split(separatorInit[0]);
        const endT = this.endTime.split(separatorEnd[0]);
        const totalHours = (Number(endT[0]) - Number(initT[0])) * 3600;
        const totalMinutes = (Number(endT[1]) - Number(initT[1])) * 60;
        return String(totalHours + totalMinutes);
      }
      return '0';
    },
    async createRule(rule) {
      const res = await this.$apollo.mutate({
        mutation: gql`
          mutation insertOneAccessRule ($input: AccessRuleInsertInput!) {
            insertOneAccessRule (data: $input) {
              _id
              name
              duration
            }
          }`,
        variables: {
          input: rule,
        },
      });
      this.$emit('accessFormSent', res);
    },
    async saveAccessRule() {
      const newRule = {
        name: this.name,
        mode: this.mode,
        initTime: this.initTime,
        duration: this.getDuration(),
        openings: this.openings.map((e) => e.split(' - ')[1]),
        enabled: true,
      };
      if (this.description !== '') {
        newRule.description = this.description;
      }
      if (this.mode === 'BADGE' && this.badgesList.length > 0) {
        newRule.badgesList = this.badgesList.map((elm) => ({
          uuid: elm.uuid,
          macAddr: elm.macAddr,
        }));
      }
      if (this.type === 'repeat') {
        newRule.weekDays = this.weekDays;
      } else {
        newRule.initDate = new Date(this.date).toISOString();
      }
      const newRuleRes = await this.createRule(newRule);
      return newRuleRes;
    },
    async getDoors() {
      const doors = await this.$apollo.query({
        query: gql`
          query openings ($query: OpeningQueryInput) {
          openings (query: $query) {
            openingId
            name
          }
        }`,
        fetchPolicy: 'network-only',
      });
      this.doors = doors.data.openings;
      this.doors.push({ openingId: '3', name: 'Demo' });
      this.openingsItems = this.doors;
      return doors.data.openings;
    },
  },
};
</script>

<style scoped>
  .main-css {
    background: white;
  }

  .btn-validation {
    display: flex;
    justify-content: flex-end;
  }
</style>
