<template>
  <div>
    <v-text-field label="Rechercher" v-model="stringToMatch"></v-text-field>
  </div>
</template>

<script>
export default {
  name: 'DefaultComponent',
  props: {
    dataTable: {
      type: Array,
      default: () => [],
    },
    fieldsToMatch: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      stringToMatch: '',
    };
  },
  created() {
    if (this.dataTable.length > 0) {
      this.$emit('filteredMatch', this.dataTable);
    }
  },
  watch: {
    stringToMatch(nVal) {
      const f = this.filterDataTable(nVal);
      this.$emit('filteredMatch', f);
    },
    dataTable(nVal) {
      this.$emit('filteredMatch', nVal);
    },
  },
  methods: {
    filterDataTable(match) {
      if (match === '') {
        return this.dataTable;
      }
      const filteredData = [];
      this.dataTable.forEach((elm) => {
        if (Object.keys(elm).includes(match)) {
          filteredData.push(elm);
        }
      });
      return this.dataTable.filter((elm) => {
        const columnToFilter = this.fieldsToMatch;
        return columnToFilter.find((column) => elm[column] && elm[column]
          .toString().toLowerCase().includes(this.stringToMatch.toLowerCase()));
      });
    },
  },
};
</script>

<style scoped>
  .main-css {
    background: #b8d5fa;
    border: 1px solid grey;
    width: 150px;
    height: 150px;
  }
</style>
