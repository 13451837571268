<template>
  <div>
    <div>
      <v-data-table
        :headers="headers"
        :items="getUserWithCompanyName"
        :items-per-page="5"
        class="elevation-0"
      >
      </v-data-table>
    </div>
  </div>
</template>

<script>
import USERS from '../graphQL/Users.gql';
import HOMES from '../graphQL/Homes.gql';

export default {
  props: {
    refresh: {
      type: Boolean,
      default: false,
    },
    badgesUUID: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      msg: 'Hi there!',
      headers: [
        { text: 'Email', value: 'email' },
        { text: 'Entreprise', value: 'companyName' },
        { text: 'Role', value: 'role' },
      ],
    };
  },
  computed: {
    getUserWithCompanyName() {
      const userWithCompanyName = [];
      if (this.users && this.homes) {
        this.users.forEach((user) => {
          const company = this.homes[0].companies.filter((c) => c.companyId === user.companyId);
          const userComplete = user;
          userComplete.companyName = company && company[0] ? company[0].name : 'unknown';
          userWithCompanyName.push(userComplete);
        });
      }
      if (this.badgesUUID.length === 0) {
        return userWithCompanyName;
      }
      return userWithCompanyName.filter((elm) => this.badgesUUID.includes(elm.badgeNumber?.uuid));
    },
  },
  apollo: {
    users: USERS,
    homes: HOMES,
  },
  watch: {
    refresh() {
      this.refreshData();
    },
  },
  methods: {
    refreshData() {
      this.$apollo.queries.users.refetch();
    },
    foundedData(el) {
      this.founded = el;
    },
  },
};
</script>

<style scoped>
.super-class {
  background: #4b79b6;
}
</style>
